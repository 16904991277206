.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: calc(100%);
  max-width: 1200px;
  padding: 0px;
}

.featured-post-cards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: 0px;
}

.posts-title {
  font-family: bogart-semibold;
  font-size: 20px;
  color: #F39C12;
  position: relative;

  width: 100%;
  height: 100%;
  margin-bottom: 25px;

  overflow: hidden;
  text-align: center;
}

.posts-title:before, .posts-title:after {
  content: "";
  display: inline-block;
  background-color: #F39C12;
  height: 2px;
  position: relative;
  vertical-align: middle;
  width: 10%;
}

.posts-title:before {
  right: 0.5em;
  margin-left: -50%;
}

.posts-title:after {
  left: 0.5em;
  margin-right: -50%;
}

#featured-posts-title {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#latest-posts-title {
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: center;

  top: 25px;
}

@media only screen and (min-width: 375px) {

}

@media only screen and (min-width: 481px) {

}

@media only screen and (min-width: 768px) {

}

@media only screen and (min-width: 1024px) {
  .home-container {
    width: calc(100% - 100px);
    height: 100%;
    padding: 50px;
  }

  .featured-post-cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

  }

  .posts-title {
    font-size: 36px;
  }

  .posts-title:before, .posts-title:after {
    width: 50%;
  }
  

  #featured-posts-title, #latest-posts-title {
    display: flex;
  }

  #latest-posts-title  {
    top: 0px;
  }
}

@media only screen and (min-width: 1200px) {

}

@media only screen and (min-width: 1600px) {

}

.home-loader-container {

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #f39c12;
  z-index: 99;

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url('../background-image-assets//Icons-Background-White-Transparent-1000X875.png'), linear-gradient(rgba(243, 156, 18, 1), rgba(243, 156, 18, 1));

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;

}