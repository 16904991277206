/* FLEX PROPERTIES BELOW */

.post-container, .post-hero-container, .post-hero-content-container, .post-content-container, .wp-block-cover, .wp-block-quote, p:has(.instagram-iframe), p:has(.youtube-iframe), .categories-container, .tags-container, .comments-container, .comment-card-container, .comment-card-container, .comment-card-hero-container, .fa-user {
  display: flex;
}

.post-container, .post-hero-container, .post-hero-content-container, .post-content-container, .wp-block-cover, .wp-block-quote, .categories-container, .tags-container, .comments-container, .comment-card-container, .comment-card-container, .comment-card-hero-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.categories-container, .tags-container, .comment-card-hero-container {
  align-items: flex-start;
}

.post-hero-content-container, .comment-card-hero-container {
  flex-grow: 1;
}

.fa-user { 
  align-items: center;
}

p:has(.instagram-iframe), p:has(.youtube-iframe) {
  flex-direction: row;
  justify-content: center;
}

@media only screen and (min-width: 481px) {

}

@media only screen and (min-width: 1024px) {

  .post-hero-content-container, .post-categories-container, .post-tags-container, .comments-container {
    align-items: flex-start;
    justify-content: flex-start;
  }

  .categories-container, .tags-container, .comment-card-hero-container {
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .post-category-copy, .post-tag-copy {
    display: inline-block;
  }

  .comment-author-name {
    flex-grow: 1;
  }
}

/* --------------------------------------------------- */

/* Height, Width, Margin, and Padding Properties */

/* WIDTH */

.post-container {
  max-width: 800px;
} 

.post-container, .post-title, .post-date, .post-content-container h2, .post-content-container p, .post-image-container, .wp-block-cover, .wp-block-separator, .wp-block-quote, .post-categories-tags-container-title, .comment-form-title, .comment-form, .comment-form-input, .comment-card-container, .comment-card-hero-container, .comment-author-name, .comment-content {
  width: calc(100%);
} 

.post-image-container img, .post-content-container h2 img, .wp-block-cover img, .instagram-iframe {
  width: 100%;
}

.youtube-iframe, .wp-block-video video {
  width: calc(100vw - 100px)
}

.wp-video video, .wp-video-shortcode, .wp-block-cover video {
  width: calc(100%) !important;
}

.post-hero-content-container, .post-content-container, .post-categories-container, .post-tags-container, .comments-container {
  width: calc(100vw - 100px);
}

.wp-block-quote p {
  width: 80%;
}

.fa-user {
  width: 50px;
}

.comment-button {
  width: 100px;
}

@media only screen and (min-width: 481px) {

  .post-hero-content-container, .post-content-container, .post-categories-container, .post-tags-container, .comments-container {
    width: calc(100vw - 150px)
  }

  p:has(iframe) {
    width: 100%;
  }

  .instagram-iframe {
    width: 60%;
  }

  .youtube-iframe, .wp-block-video video {
    width: calc(100vw - 150px)
  }

  .comment-button {
    width: 150px;
  }

}

@media only screen and (min-width: 1024px) {
  .post-container {
    width: calc(100% - 100px);
  } 

  .post-hero-content-container, .post-content-container, .post-categories-container, .post-tags-container, .comments-container {
    width: calc(100vw - 200px);
    max-width: 700px;
  }

  .youtube-iframe, .wp-block-video video {
    width: calc(100vw - 200px);
    max-width: 700px;
  }

  .comment-form, .comment-card {
    /* width: 100%; */
  }

  .comment-button {
    width: 125px;
  }

  .post-image-container img, .post-content-container h2 img, .wp-block-cover img {
    width: 100%;
  }
  
 }


/* Height */

.post-image-container {
  height: 500px;
}

.wp-block-cover {
  height: 325px;
}

.wp-block-cover:has(video), .wp-block-video {
  height: fit-content;
}

.post-image-container img, .post-content-container h2 img {
  height: 100%
}

.wp-block-cover img {
  height: calc(100% - 25px)
}

.instagram-iframe {
  height: 350px;
}

.youtube-iframe, .wp-block-video video {
  height: calc((100vw - 100px) * 9 / 16)
}

.wp-video video, .wp-video-shortcode, .wp-block-cover video {
  max-height: 500px;
}

.fa-user {
  height: 50px;
}

.comment-button {
  height: calc(100px / 2);
}

@media only screen and (min-width: 481px) {
  .wp-block-cover {
    height: 425px;
  }

  .instagram-iframe {
    height: 450px;
  }

  .youtube-iframe, .wp-block-video video {
    height: calc((100vw - 150px) * 9 / 16)
  }

  .comment-button {
    height: calc(150px / 2);
  }

  .post-content-container p, .comment-content {
    /* height: inherit; */
  }
}

@media only screen and (min-width: 1024px) {
  .post-container {
    /* height: 100%; */
  } 

  .wp-block-cover {
    height: calc(50vh + 25px);
  }
  
  .wp-block-cover video {
    height: 40vh;
  }

  .post-image-container img, .post-content-container h2 img, .wp-block-cover img {
    height: calc(100% - 25px);
  }

  .youtube-iframe, .instagram-iframe {
    height: 500px;
  }

  .youtube-iframe, .wp-block-video video {
    height: calc((min(calc(100vw - 200px), 700px)) * 9 / 16)
  }

  .comment-button {
    height: calc(125px / 2);
  }

}


/* Z-Index */

.post-hero-content-container, .post-categories-container, .post-tags-container, .comments-container, .post-image-container, .post-content-container h2:has(img), .wp-block-cover {
  z-index: 2;
}

@media only screen and (min-width: 481px) {
  
}

@media only screen and (min-width: 1024px) {
  
}

/* MARGIN */

.post-title, .post-date, .post-content-container h2, .wp-block-cover, .wp-block-separator, .wp-block-quote, .post-content-container p, .post-categories-tags-container-title, .post-category-copy, .post-tag-copy, .comment-form-title, .comment-form-input, .comment-button, .comment-author-name, .comment-date, .comment-content {
  margin: 0px;
}

.post-image-container {
  /* margin-bottom: 0px; */
}

.fa-user {
  /* margin-left: 0px; */
}

.fa-user::before {
  margin: 0px auto;
}

.invalid {
  margin: 1px 5px;
}

.comment-button {
  margin-top: 25px;
  margin-left: auto;
}

@media only screen and (min-width: 481px) {
  
}

@media only screen and (min-width: 1024px) {
  .post-hero-content-container, .post-categories-container, .post-tags-container, .comments-container {
    margin: 25px 0 0 0;
  }

  .post-image-container {
    margin-bottom: 2.5vh;
  }

  .post-category-copy, .post-tag-copy {
    margin-bottom: 5px;
    margin-right: 10px;
  }

  .comment-form-input {
    margin: 10px 0px 15px 0px;
  }

  .comments-container {
    margin-bottom: calc(5vh + 100px) ;
  }

  .comment-form, .comment-card {
    margin-bottom: 50px;
  }

}

/* PADDING */

.post-container {
  padding: 0px;
} 

.post-hero-content-container, .post-categories-container, .post-tags-container, .comments-container {
  padding: 0px 50px;
}

.comment-card-container {
  padding: 50px 0px;
}

.post-title {
  padding-top: 50px;
}

 .post-content-container h2:not(:has(img)), .post-categories-tags-container-title, .comment-form-title, .comment-author-name, .comment-content:first-of-type {
  padding-top: 25px;
}

.post-title, .post-date, .post-content-container h2, .wp-block-cover, .wp-block-separator, .wp-block-quote, .post-content-container p, .post-categories-tags-container-title, .comment-form, .comment-form-title, .comment-card, .comment-author-name, .comment-content {
  padding-bottom: 25px;
}

.wp-block-quote {
  padding-bottom: 0px;
}

.post-category-copy, .post-tag-copy {
  padding-bottom: 5px;
}

.comments-container > *:last-child {
  padding-bottom: calc(75px + 75px);
}

.fa-user {
  padding: 20px;
}

.comment-form-input {
  padding: 20px 2px 25px 2px;
}

.comment-button {
  padding: 0px 10px;
}

@media only screen and (min-width: 481px) {

  .post-hero-content-container, .post-categories-container, .post-tags-container, .comments-container {
    padding: 0px 75px;
  }

  .comment-button {
    padding: 0px 10px;
  }

}

@media only screen and (min-width: 1024px) {
  .post-container, .post-hero-content-container, .post-categories-container, .post-tags-container, .comments-container {
    padding: 50px;
  } 

  .post-title {
    padding-top: 25px;
  }

  /* .post-hero-content-container, .post-categories-container, .post-tags-container, .comments-container {
    padding: 0px 75px;
  } */

  .post-category-copy, .post-tag-copy {
    padding-bottom: 0px;
  }

  .comments-container > *:last-child {
    padding-bottom: 0px;
  }

  .comment-form-input {
    padding: 10px 2px;
  }

  .comment-button {
    padding: 0px 10px;
  }

}

/* Background Properties Below */

.post-hero-content-container, .post-categories-container, .post-tags-container, .comments-container {
  background-color: rgba(256, 256, 256, 1);
  background-image: url('../background-image-assets/Icons-Background-Blue-Transparent-1000X875.png');
  background-size: 240%;
  background-repeat: repeat;
  background-position: center center;

  box-shadow: 5px 0px 5px -5px white, -5px 0px 5px -5px white;
  -moz-box-shadow: 5px 0px 5px -5px white, -5px 0px 5px -5px white; 
  -webkit-box-shadow: 5px 0px 5px -5px white, -5px 0px 5px -5px white;
}

.post-container, .post-content-container div, .post-content-container p, .post-content-container div p, .post-title, .post-date, .post-content-container h2, .wp-video, .wp-block-cover video, .wp-block-quote, .wp-block-separator, .categories-container, .tags-container, .comment-form, .post-categories-tags-container-title, .comment-form-title, .comment-card-container, .comment-author-name, .comment-form-input, .comment-button {
  background: white;
}

.comment-button {
  background-color: #f39c12;
}

@media only screen and (min-width: 481px) {

  .post-hero-content-container, .post-categories-container, .post-tags-container, .comments-container {
    background-size: 60%;
  }

  /* .post-content-container h2:has(img), .wp-block-cover {
    background: white;
  } */

}

@media only screen and (min-width: 1024px) {

  .post-container, .post-hero-content-container, .post-content-container div, .post-content-container p, .post-title, .post-date, .post-content-container h2, .wp-video, .wp-block-cover video, .post-categories-container, .post-tags-container, .categories-container, .tags-container, .comments-container, .comment-form, .post-categories-tags-container-title, .comment-form-title, .comment-author-name, .comment-form-input {
    background: none;
    background-image: none;
  }

  .post-hero-content-container, .post-categories-container, .post-tags-container, .comments-container {
    background-color: rgba(256, 256, 256, 1);
  }

}

/* Text Propertoes Below */

/* Text-Alignment */

.post-title, .post-date, .post-content-container h2, .wp-block-cover, .wp-block-separator, .wp-block-quote, .post-content-container p, .post-content-container div p, .post-categories-tags-container-title, .comment-form-title, .comment-author-name, .comment-content {
  text-align: left;
}

.wp-block-quote p {
  text-align: center;
}

.fa-user {
  vertical-align: middle;
}

/* Text Color */

.post-title, .post-content-container h2, .post-content-container p, .post-categories-tags-container-title, .comment-form-title, .comment-author-name, .fa-user, .comment-content {
  color: #367bd6; 
}

.post-date, .post-content-container p a, .post-category-copy, .post-tag-copy, .comment-form-input, .comment-button, .comment-date {
  color: #f39c12;
}

.comment-button {
  color:white;
}

.comment-form-input {
  color: darkgray;
}

@media only screen and (min-width: 481px) {

}

@media only screen and (min-width: 1024px) {

}

/* Font Properties (i.e. Size, Spacing, Weight, Style...) */

.post-title, .post-content-container h2:not(:has(img)), .post-categories-tags-container-title, .comment-form-title, .comment-author-name {
  font-size: clamp(24px, 5vw, 28px);
  letter-spacing: 1.2px;
}

.post-date, .post-content-container p, .post-category-copy, .post-tag-copy, .comment-form-input, .comment-button, .comment-date, .comment-content {
  font-family: Arial, Helvetica, sans-serif;
  font-size: clamp(18px, 3vw, 24px);
  font-weight: 400;
}

.post-content-container p, .comment-content {
  letter-spacing: 2.0px;
}

.wp-block-quote {
  font-style: italic;
}

.fa-user {
  font-size: 30px;
}

.comment-button {
  text-shadow: none;
}

@media only screen and (min-width: 481px) {

}

@media only screen and (min-width: 1024px) {
  .post-title, .post-content-container h2, .wp-block-cover img, .post-categories-tags-container-title, .comment-form-title, .comment-author-name {
    font-size: clamp(28px, 5vw, 36px);
  }
}

/* Image-Properties */

.post-image-container img, .post-content-container h2 img, .wp-block-cover img {
  mix-blend-mode: multiply;
  filter: grayscale(0%) contrast(1) brightness(90%);
}

.post-image-container img, .wp-block-cover img {
  object-fit: cover;
}

@media only screen and (min-width: 481px) {

}

@media only screen and (min-width: 1024px) {

}

/* Borders */

.wp-block-separator, .comment-form-input, .comment-button {
  border: none;
}

.wp-block-separator, .comment-card-container {
  border-top: 2px solid #f39c12;
}

.invalid {
  border: 2px solid red;
}

.fa-user {
  border: solid #367bd6 2px;
  border-radius: 50%;
}

@media only screen and (min-width: 481px) {

}

@media only screen and (min-width: 1024px) {

}

/* Outlines */

.post-image-container img, .post-content-container h2 img, .wp-block-cover img {
  outline: 2px solid white;
  outline-offset: -25px;
}

.post-content-container h2 img, .wp-block-cover img {
  outline: none;
}

@media only screen and (min-width: 481px) {

}

@media only screen and (min-width: 1024px) {

  .post-hero-content-container, .post-categories-container, .post-tags-container, .comments-container {
    outline: 2px solid #f39c12;
    outline-offset: -25px;
  }

  .post-image-container:hover img  {
    outline: 2px solid #f39c12;
    transform: scale(1.02);
  }

}

/* Shadows */

.post-image-container img, .post-content-container h2 img, .wp-block-cover img {
  box-shadow: 0 10px 30px -15px #0a192f;
}

.comment-form-input {
  --webkit-box-shadow: 0 5px 5px -6px lightgray;
  --Moz-Box-Shadow: 0 5px 5px -6px lightgray;
  box-shadow: 0 5px 5px -6px lightgray;
}


@media only screen and (min-width: 481px) {

}

@media only screen and (min-width: 1024px) {
  .post-hero-content-container, .post-categories-container, .post-tags-container, .comments-container {
    box-shadow: 0 10px 30px -15px #0a192f;
  }
}

/* TRANSITIONS & PSEUDO ELEMENTS */

@media only screen and (min-width: 1024px) {

  .post-image-container, .post-image-container img, .post-content-container h2 img, .wp-block-cover img {
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .post-category-copy::after, .post-tag-copy::after {
    content: ", "
  }
  
  .post-category-copy:last-child::after, .post-tag-copy:last-child::after {
    content: ""
  }

}

.post-loader-container {

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #f39c12;
  z-index: 99;

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url('../background-image-assets//Icons-Background-White-Transparent-1000X875.png'), linear-gradient(rgba(243, 156, 18, 1), rgba(243, 156, 18, 1));

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;

}