.featured-post-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100vw;

  margin-bottom: 0px;
}

.featured-post-card-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  height: calc(100vh - 100px);
  padding: 50px;

  outline: 2px solid white;
  outline-offset: -25px;

  z-index: 1;
} 


.featured-post-card-container:not(:first-of-type) .featured-post-card-content-container {

}

.featured-post-card-container:nth-child(odd) .featured-post-card-content-container {

}

.featured-post-card-container:first-of-type .featured-post-card-content-container {
  height: calc(100vh - 100px - 75px)
} 

.featured-post-card-header-container {
  display: flex;
  flex-direction: column;
}

.featured-post-card-title {
  color: white;
  font-size: clamp(24px, 5vw, 28px);
  letter-spacing: 1.2px;

  margin-top: 10px;
  margin-bottom: 10px;
}

.featured-post-card-container:first-of-type .featured-post-card-title {
  font-size: clamp(28px, 5vw, 36px);

  margin-top: 25px;
  margin-bottom: 25px;
}

.featured-post-card-date {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: clamp(18px, 3vw, 24px);
  font-weight: 400;

  margin-top: 0px;
  margin-bottom: 0px;
}

.featured-post-card-container:first-of-type .featured-post-card-date {
}

.featured-post-card-excerpt-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
}

.featured-post-card-excerpt {

  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: clamp(18px, 3vw, 24px);
  font-weight: 400;
  letter-spacing: 2.0px;;

  margin-top: 25px;
  margin-bottom: 25px;

  height: inherit;

  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.featured-post-card-link {
    color: #f39c12;
    font-family: Arial, Helvetica, sans-serif;
    font-style: italic;
    font-size: clamp(18px, 3vw, 24px);
    font-weight: 500;
    letter-spacing: 1.4px;
    vertical-align: bottom;
  
    margin-top: 0px;
    margin-bottom: 0px;
  
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    position: relative;
}

.featured-post-card-image-container {
  width: 100%;
  height: calc(100vh - 100px);
  z-index: 0;

  transform: translateY(calc(-1 * (100vh)));
  margin-bottom: calc(-1 * (100vh - 100px));
  /* opacity: 0.25; */
}

.featured-post-card-container:first-of-type .featured-post-card-image-container {
  height: calc(100vh - 100px - 75px);
  transform: translateY(calc(-1 * (100vh - 75px)));
  margin-bottom: calc(-1 * (100vh - 100px - 75px));
} 

.featured-post-card-image {
  width: 100%;
  height: calc(100vh);
  /* height: calc(100vh - 100px); */
  object-fit: cover;

  /* outline: 2px solid white; */
  outline-offset: -25px;

  filter: grayscale(0%) contrast(1) brightness(90%);

  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.featured-post-card-container:first-of-type .featured-post-card-image {
  height: calc(100vh - 75px);
}

.featured-post-card-container:not(:first-of-type) .featured-post-card-image {
  /* border-radius: 12%; */
}

.featured-post-card-container:first-of-type .featured-post-card-image {

  /* mix-blend-mode: multiply; */
  filter: grayscale(0%) contrast(1) brightness(90%);
  /* filter: grayscale(100%) contrast(1) brightness(90%); */

  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media only screen and (min-width: 375px) {

}

@media only screen and (min-width: 481px) {

}

@media only screen and (min-width: 768px) {

}

@media only screen and (min-width: 1024px) {

  .featured-post-card-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-direction: row-reverse;
  
    width: 100%;
    margin-bottom: 5vh;
  }

  .featured-post-card-container:nth-child(odd) {
    flex-direction: row;
  }
  
  .featured-post-card-container:first-of-type {
    flex-direction: column-reverse;
  
    /* margin-bottom: 10vh; */
  }

  .featured-post-card-container:nth-of-type(2) {
    margin-top: 5vh;
  }
  
  .featured-post-card-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  
    width: calc(50% - 100px - 25px);
    height: calc(500px - 100px);
    padding: 50px;
  
    background-color: rgba(256, 256, 256, 1);
    box-shadow: 0 10px 30px -15px #0a192f;
    margin: 0 0 0 50px;
  
    outline: 2px solid #f39c12;
    outline-offset: -25px;
  
    z-index: 2;
  } 

  .featured-post-card-container:first-of-type .featured-post-card-content-container {
    height: calc(500px - 100px);
  }
  
  .featured-post-card-container:not(:first-of-type) .featured-post-card-content-container {
    border-radius: 12%;
  }
  
  .featured-post-card-container:nth-child(odd) .featured-post-card-content-container {
    margin: 0 50px 0 0;
  }
  
  .featured-post-card-container:first-of-type .featured-post-card-content-container {
    width: calc(100% - 100px);
    margin: 25px 0 0 0;
  } 
  
  .featured-post-card-header-container {
    display: flex;
    flex-direction: column;
  }
  
  .featured-post-card-title {
    color: #367bd6;
    font-size: clamp(24px, 5vw, 28px);
    letter-spacing: 1.2px;
  
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .featured-post-card-container:not(:first-of-type) .featured-post-card-title {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
  
  .featured-post-card-container:first-of-type .featured-post-card-title {
    font-size: clamp(28px, 5vw, 36px);
  
    margin-top: 25px;
    margin-bottom: 25px;
  }
  
  .featured-post-card-date {
    color: #f39c12;
    /* color: #367bd6; */
    font-family: Arial, Helvetica, sans-serif;
    font-size: clamp(18px, 3vw, 24px);
    font-weight: 400;
  
    margin-top: 0px;
    margin-bottom: 0px;
  }
  
  .featured-post-card-container:first-of-type .featured-post-card-date {
  }
  
  .featured-post-card-excerpt-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-grow: 1;
  }
  
  .featured-post-card-container:first-of-type .featured-post-card-excerpt-container {
  
  }
  
  .featured-post-card-excerpt {
    color: #367bd6;
    font-family: Arial, Helvetica, sans-serif;
    font-size: clamp(18px, 3vw, 24px);
    font-weight: 400;
    letter-spacing: 2.0px;;
  
    margin-top: 25px;
    margin-bottom: 25px;
  
    height: inherit;
  }
  
  .featured-post-card-container .featured-post-card-excerpt p:first-of-type {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  
  .featured-post-card-container .featured-post-card-excerpt p:not(:first-of-type) {
    margin-top:10px;
    margin-bottom: 0px;
  }
  
  .featured-post-card-container:not(:first-of-type) .featured-post-card-excerpt {
      /* max-height: 175px;
      text-overflow: ellipsis;
      overflow: hidden; */
  
      /* line-height: 1em;
      height: calc(7 * 1em);
      overflow-y: hidden; */
  
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;  
      overflow: hidden;
  }
  
  .featured-post-card-container:first-of-type .featured-post-card-excerpt {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  
  .featured-post-card-link {
    font-size: clamp(18px, 3vw, 24px);
    font-weight: 800;
    letter-spacing: 4.5px;

    flex-grow: 1;

    /* display: block; */
    width: fit-content;
  }

  .featured-post-card-link::after {
    content: "\2192";
    display: inline-block;
    right: -80px;
    position: absolute;
    /* top: 50%; */
    transition: left 0.1s;
  }

  .featured-post-card-link::after {
    color: #f39c12;
    width: 50px;
    transform: scale(2, 1.5)

    /* height: 2px;
    width: 50px; */
  }

  .featured-post-card-link:hover::after {
   right: -85px;
  }
  
  .featured-post-card-container:first-of-type .featured-post-excerpt {
    margin-bottom: 25px;
  }
  
  .featured-post-card-image-container {
    width: calc(50% - 25px);
    height: 500px;

    transform: translateY(0px);
    margin-bottom: 0px;
    opacity: 1;
  }
  
  .featured-post-card-container:first-of-type .featured-post-card-image-container {
  
    width: calc(100%);
    height: 500px;
    transform: translateY(0px);
    margin-bottom: 0px;
  
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  
    z-index: 2;
  }
  
  .featured-post-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  
    outline: 2px solid white;
    outline-offset: -25px;
    box-shadow: 0 10px 30px -15px #0a192f;
  
    filter: grayscale(0%) contrast(1) brightness(90%);
  
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  
  .featured-post-card-container:not(:first-of-type) .featured-post-card-image {
    border-radius: 12%;
  }
  
  .featured-post-card-container:first-of-type .featured-post-card-image {
  
    height: 100%;
    
    mix-blend-mode: multiply;
    filter: grayscale(0%) contrast(1) brightness(90%);
  
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  
  .featured-post-card-container:hover .featured-post-card-image {
    outline: 2px solid #f39c12;
    transform: scale(1.05);
    /* filter: grayscale(100%) contrast(1) brightness(90%); */
  }
  
  .featured-post-card-container:first-of-type:hover .featured-post-card-image {
    transform: scale(1.02);
    /* filter: grayscale(100%) contrast(1) brightness(90%); */
  }
}

@media only screen and (min-width: 1200px) {

}

@media only screen and (min-width: 1600px) {
  .featured-post-card-container {
    max-width: 1200px;
  
    margin-bottom: 5vh;
  }
}