.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  min-height: 100vh;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.postDetail-container:last-child {
    margin-bottom: 100px;
}

.about-hero-image-container {
  width: 100%;
  height: 500px;
  z-index: 2;

  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.about-hero-image {
  width: 100%;
  height: 100%;

  object-fit: cover;

  outline: 2px solid white;
  outline-offset: -25px;
  box-shadow: 0 10px 30px -15px #0a192f;

  mix-blend-mode: multiply;
  filter: grayscale(0%) contrast(1) brightness(90%);

  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.about-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: calc(100vw - 100px);
  padding: 0px 50px;

  background-color: rgba(256, 256, 256, 1);
  background-image: url('../background-image-assets/Icons-Background-Blue-Transparent-1000X875.png');
  background-size: 240%;
  background-repeat: repeat;
  background-position: center center;

  box-shadow: 5px 0px 5px -5px white, -5px 0px 5px -5px white;
  -moz-box-shadow: 5px 0px 5px -5px white, -5px 0px 5px -5px white; 
  -webkit-box-shadow: 5px 0px 5px -5px white, -5px 0px 5px -5px white;
}

.social-icons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: calc(100%);
  padding: 50px 0px;
  background: white;
}

.fab, .far {
  color: #f39c12;
  font-size: 40px;

  margin-left: 10px;
  margin-right: 10px;
}

.about-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-bio-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: calc(100%);
  padding-bottom: 50px;
  padding-bottom: 100px;
  background: white;
}

.about-bio-subtitle, .about-bio, .about-me-cta {
  margin: 0px;
  padding-bottom: 25px;
}

.about-bio-subtitle, .about-me-cta {
  color: #f39c12;
  text-align: center;
}

.about-me-cta a {
  color: #367bd6; 
  font-weight: 600;
  text-decoration: none;
  text-decoration-line: none;
}

.about-bio-subtitle {
  font-size: clamp(24px, 5vw, 28px);
  letter-spacing: 1.2px;
}

.about-bio {
  color: #367bd6; 
  font-family: Arial, Helvetica, sans-serif;
  font-size: clamp(18px, 3vw, 24px);
  font-weight: 400;
}

.about-bio li {
  padding-bottom: 10px;
}

.about-bio li::marker {
  color: #f39c12
}

@media only screen and (min-width: 481px) {
  .about-content-container {
    width: calc(100vw - 150px);
    padding: 0px 75px;
    background-size: 60%;
  }

}

@media only screen and (min-width: 1024px) {
  .about-container {
    width: calc(100% - 100px);
    max-width: 800px;

    margin-bottom: calc(5vh + 100px);
    padding: 50px 0px;
  }

  .about-hero-image-container {
    margin-bottom: 2.5vh;
  }

  .about-hero-image {
    width: 100%;
    height: calc(100% - 25px);
  }

  .about-hero-image:hover  {
    outline: 2px solid #f39c12;
    transform: scale(1.02);
  }

  .about-content-container {
    width: calc(100vw - 200px);
    max-width: 700px;
    padding: 0px 50px;

    background: none;
    background-image: none;
    background-color: rgba(256, 256, 256, 1);

    outline: 2px solid #f39c12;
    outline-offset: -25px;
  }

  .social-icons-container {
    padding-top: 75px;
  }

  .fab, .far {
    margin-left: 20px;
    margin-right: 20px;
  }

  .about-bio-container {
    padding-bottom: 50px;
  }
} 
