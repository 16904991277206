.post-cards-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  width: calc(100% - 100px);
  height: 100%;
  padding: 50px;
  margin-top: -75px;

  background-color: rgba(256, 256, 256, 0.9);
  box-shadow: none;

  outline: 2px solid #f39c12;
  outline-offset: -40px -25px -25px -25px;
  outline-offset: -25px;

  overflow: visible;
}

.post-cards-container:last-child {
  margin-bottom: 75px;
}

.infinite-scroll-loader {

  width: 100%;
  /* min-height: 250px; */
  margin-top: 5vh;
  margin-bottom: 5vh;
  scale: 0.5;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.infinite-scroll-loader svg {
  display: block;
  width: 50%;
}


@media only screen and (min-width: 375px) {

}

@media only screen and (min-width: 481px) {

}

@media only screen and (min-width: 768px) {

}

@media only screen and (min-width: 1024px) {
  .post-cards-container {
    margin-top: 5vh;
    max-width: 1200px;
  
    background-color: rgba(256, 256, 256, 0.9);
    box-shadow: 0 10px 30px -15px #0a192f;

  }

  .post-cards-container:last-child {
    margin-bottom: 100px;
  }
  
}

@media only screen and (min-width: 1200px) {

}

@media only screen and (min-width: 1600px) {

}