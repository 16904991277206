.layout-container {
  width: 100vw;
  overflow-x: hidden;
  
  display: flex;
  flex-direction: column;
  align-items: center;

  background-image: linear-gradient(rgba(54, 123, 214, 0.1), rgba(54, 123, 214, 0.1)), url('https://i.imgur.com/AxblwEN.png');
  background-image: url('../../background-image-assets/Icons-Background-White-Transparent-1000X875.png'), linear-gradient(rgba(54, 123, 214, 1), rgba(54, 123, 214, 1));
  background-size: contain;
  background-repeat: repeat;
  background-position: center center;
  background-attachment: fixed;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;

}

.body-container {
    width: 100%;
    min-height: calc(100vh - 75px);
    min-height: 90vh;
    /* height: 100%; */

    position: relative;
    top: 75px;
    /* background: rgba(93, 106, 122, 0.5); */

    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;

    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
}

/* .body-container:last-child {
  margin-bottom: 25px;
} */



  @media only screen and (min-width: 375px) {

  }
  
  @media only screen and (min-width: 481px) {
  
  }
  
  @media only screen and (min-width: 768px) {
  
  }
  
  @media only screen and (min-width: 1024px) {
    .body-container {
      min-height: calc(100vh - 100px);
      top: 100px;
    }
  }
  
  @media only screen and (min-width: 1200px) {
  
  }
  
  @media only screen and (min-width: 1600px) {
  
  }