.loader-container {

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #f39c12;
  z-index: 99;

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url('../../background-image-assets//Icons-Background-White-Transparent-1000X875.png'), linear-gradient(rgba(243, 156, 18, 1), rgba(243, 156, 18, 1));

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;

}

.logo-wrapper {

  display: flex;
  align-items: center;
  justify-content: center;

  width: max-content;
  /* max-width: 500px; */
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  scale: 1;

  background-color: #f39c12;
  text-align: center;
  /* padding: 50px; */

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.logo-wrapper svg {

  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  user-select: none;

  background-color: #f39c12;

  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {

  .logo-wrapper {
    /* max-width: 50vw; */
    width: 30vw;
    scale: 1;
  }

  .logo-wrapper svg {
    width: 100%;
    height: 100%;
  }

}