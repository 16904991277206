.post-card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 1200px;
  /* height: 200px; */
  min-height: 250px;

  border-bottom: 1px solid #F39C12;
}

.post-card-container:last-of-type {
  border-bottom: none;
}

.post-card-image-container {
  display: none;
}

.post-card-content-container {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.post-card-title {
  color: #367bd6;
  font-size: 20px;
  letter-spacing: 1.2px;

  margin-top: 10px;
  margin-bottom: 0px;

  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.post-card-date {
  color: #367bd6;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;

  margin-top: 0px;
  margin-bottom: 0px;
}

.post-card-container:hover .post-card-title,
.post-card-container:hover .post-card-date {
  color: #f39c12;
}


@media only screen and (min-width: 375px) {

}

@media only screen and (min-width: 481px) {

}

@media only screen and (min-width: 768px) {

}

@media only screen and (min-width: 1024px) {
  .post-card-container {
    height: 300px;
  }

  .post-card-image-container {
    display: inline-block;
    
    width: 50%;
    height: 100%;
  
    overflow: hidden;
  }
  
  .post-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  
  
    filter: grayscale(0%) contrast(1) brightness(90%);
    /* filter: grayscale(100%) contrast(1) brightness(90%); */
  
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .post-card-content-container {
    width: 50%;
    padding: 50px;
  }

  .post-card-title {
    font-size: clamp(24px, 5vw, 28px);
    margin-bottom: 10px;
  }
  
  .post-card-date {
    color: #367bd6;
    font-size: clamp(18px, 3vw, 24px);
    font-weight: 400;
  }

  .post-card-container:hover .post-card-image {
    transform: scale(1.05);
    /* filter: grayscale(0%) contrast(1) brightness(90%); */
  }
}

@media only screen and (min-width: 1200px) {

}

@media only screen and (min-width: 1600px) {

}