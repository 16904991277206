.App {
  text-align: center;
}

.app-container {
  width: 100%;
  margin: 0px;
  font-family: bogart-semibold;
  height: 100%;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

@media only screen and (min-width: 375px) {

}

@media only screen and (min-width: 481px) {

}

@media only screen and (min-width: 768px) {

}

@media only screen and (min-width: 1024px) {

}

@media only screen and (min-width: 1200px) {

}

@media only screen and (min-width: 1600px) {

}