.posts-by-category-container { 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: calc(100%);
  max-width: 1200px;
  padding: 0px;  width: 100%;

}

.posts-by-category-title {
  width: 33%;
  margin-bottom: 25px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* flex-wrap: wrap; */

  font-family: bogart-semibold;
  font-size: 20px;
  color: #F39C12;

  word-wrap: break-word;
  /* overflow: hidden; */
  text-align: center;

  position: relative;
  top: 25px;
}

.posts-by-category-title:before, .posts-by-category-title:after {
  content: "";
  display: inline-block;
  background-color: #F39C12;
  height: 2px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.posts-by-category-title:before {
  right: 0.5em;
  margin-left: -50%;
}

.posts-by-category-title:after {
  left: 0.5em;
  margin-right: -50%;
}

.posts-by-category-container .post-cards-container {
  margin-top: -135px;
  padding-top: 100px;
}

.posts-by-category-loader-container {

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #f39c12;
  z-index: 99;

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url('../background-image-assets//Icons-Background-White-Transparent-1000X875.png'), linear-gradient(rgba(243, 156, 18, 1), rgba(243, 156, 18, 1));

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;

}

.posts-by-category-logo-wrapper {

  display: flex;
  align-items: center;
  justify-content: center;

  width: max-content;
  /* max-width: 500px; */
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  scale: 1;

  background-color: #f39c12;
  text-align: center;
  /* padding: 50px; */

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.posts-by-category-logo-wrapper svg {

  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  user-select: none;

  background-color: #f39c12;

  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 768px) {
  .posts-by-category-container .post-cards-container {
    margin-top: -105px;
    padding-top: 75px;
  }

  .posts-by-category-logo-wrapper {
    /* max-width: 50vw; */
    width: 30vw;
    scale: 1;
  }

  .posts-by-category-logo-wrapper svg {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  
  .posts-by-category-container { 
    width: calc(100% - 100px);
    height: 100%;
    padding: 50px;
  }

  .posts-by-category-title {
    font-size: 36px;
  }

  .posts-by-category-title:before, .posts-by-category-title:after {
    width: 50%;
  }

  .posts-by-category-title {
    top: 50px
  }

  .posts-by-category-container .post-cards-container {
    margin-top: -170px;
    padding-top: 220px;
  }

}